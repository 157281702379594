import(/* webpackMode: "eager" */ "/agent/_work/3/s/apps/machine-parts/storefront-next-app/app/global.css");
import(/* webpackMode: "eager" */ "/agent/_work/3/s/apps/machine-parts/storefront-next-app/src/providers/Providers.tsx");
import(/* webpackMode: "eager" */ "/agent/_work/3/s/libs/machine-parts/storefront/data-access/src/lib/azure/application-insights/Provider.tsx");
import(/* webpackMode: "eager" */ "/agent/_work/3/s/libs/machine-parts/storefront/utils/src/lib/hooks/api/address/useAddressLookup.tsx");
import(/* webpackMode: "eager" */ "/agent/_work/3/s/libs/machine-parts/storefront/utils/src/lib/hooks/api/address/useProvinceSearch.tsx");
import(/* webpackMode: "eager" */ "/agent/_work/3/s/libs/machine-parts/storefront/utils/src/lib/hooks/useLocalisation.tsx");
import(/* webpackMode: "eager" */ "/agent/_work/3/s/libs/machine-parts/storefront/utils/src/lib/hooks/useTailwindBreakpoint.client.ts");
import(/* webpackMode: "eager" */ "/agent/_work/3/s/libs/machine-parts/storefront/utils/src/lib/hooks/useTranslation.tsx");
import(/* webpackMode: "eager" */ "/agent/_work/3/s/libs/machine-parts/storefront/utils/src/lib/hooks/useUrlParams.tsx");
import(/* webpackMode: "eager" */ "/agent/_work/3/s/libs/machine-parts/storefront/utils/src/lib/mixpanel/MixpanelProvider.tsx");
import(/* webpackMode: "eager" */ "/agent/_work/3/s/libs/machine-parts/storefront/utils/src/lib/providers/cypress/CypressPageRenderedProvider.client.tsx");
import(/* webpackMode: "eager" */ "/agent/_work/3/s/libs/machine-parts/storefront/utils/src/lib/providers/LanguageProvider.tsx");
import(/* webpackMode: "eager" */ "/agent/_work/3/s/libs/shared/client-connectors/shopify/src/hook.ts");
import(/* webpackMode: "eager" */ "/agent/_work/3/s/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/agent/_work/3/s/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Fira_Code\",\"arguments\":[{\"weight\":\"500\",\"display\":\"swap\",\"subsets\":[\"latin\"]}],\"variableName\":\"fontFiraCode\"}");
import(/* webpackMode: "eager" */ "/agent/_work/3/s/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"900\"],\"display\":\"swap\",\"subsets\":[\"latin\"]}],\"variableName\":\"fontRoboto\"}")